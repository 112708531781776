import { useSelector } from 'react-redux';
import { globalSel } from '../../store/global';

function useFeatureToggle(featureKey) {
    const gameConfigData = useSelector(globalSel.gameConfigDataSelector);
    const isFeatureEnabled = getFeatureValue(gameConfigData, featureKey);
    return isFeatureEnabled;
}

function getFeatureValue(data, featureKey) {
    try {
        return JSON.parse(data[featureKey]);
    } catch (error) {
        return false;
    }
}

export { useFeatureToggle };
