import React from 'react';

import { Box } from 'portal-modules/core-ui';
import Skeleton from '@material-ui/lab/Skeleton';
import { AccountModalStyled } from '../AccountModal.styled';

const PaymentModalSkeleton = () => {
    return (
        <Box
            component={AccountModalStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
        >
            <Box height={[160, 250]} width="100%">
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>

            <Box height={64} width={300} p={2}>
                <Skeleton variant="text" animation="wave" height="100%" width="100%" />
            </Box>
        </Box>
    );
};

export default PaymentModalSkeleton;
