import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from 'portal-modules/core-ui';

import { useTranslation } from '../../../../hooks/useTranslation';
import { Button } from '../../ui-components/Button/Button';
import WhiteHighlightedText from '../../WhiteHighlightedText/WhiteHighlightedText';
import { dailyRewardsOp, dailyRewardsSel } from '../../../../store/dailyReward';
import { shopSel } from '../../../../store/shop';
import { DAILY_LOGIN_CALENDAR_MODAL } from '../../../../constants/modals.constants';
import { setGAEvent } from '../../../../helpers/GA4Helper';

const Unclaimed = () => {
    const dispatch = useDispatch();
    const t = useTranslation();
    const theme = useTheme();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });

    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const commodities = useSelector(shopSel.commoditiesSelector);
    const dailyProgress = dailyRewards?.DailyProgress;

    const showDailyLoginCalendar = () => {
        setGAEvent('clickEvent', {
            screen_id: 'web_store',
            screen_name: 'web_store',
            screen_type: 'screen',
            screen_category: 'daily_reward',
            interaction_type: 'tap',
            interaction_object: 'claim',
            interaction_object_category: 'display',
            flow_order: '0',
        });
        dispatch(
            dailyRewardsOp.handleOpenModal({
                open: true,
                variant: DAILY_LOGIN_CALENDAR_MODAL,
            }),
        );
    };

    if (!dailyRewards) return false;

    const activeDailyReward = dailyRewards?.CalendarGifts?.[dailyProgress];
    const activeGifts = activeDailyReward?.Gifts;

    return (
        <Box className="WP-calendar-banner-container">
            {dailyRewards?.CalendarGifts?.[dailyProgress] && (
                <Box className="WP-unclaimed-gifts">
                    {activeGifts?.map((data, index) => (
                        <Box key={index} textAlign="center" position="relative">
                            <Box
                                component="img"
                                width={40}
                                src={commodities?.[data?.CommodityKey]?.image}
                                alt="gift-img"
                            />
                            <Box className="WP-unclaimed-gift-quantities">
                                <WhiteHighlightedText
                                    text={data?.Quantity}
                                    fontSize="p18"
                                    textCenter
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}

            {breakpointsSM && (
                <Box className="WP-daily-reward-banner-text">
                    <Box
                        component={Typography}
                        className="WP-daily-reward-banner-title"
                        variant={['p12', 'p14', 'p14', 'h6', 'h6']}
                        lineHeight="18px"
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={1}
                    >
                        {t('daily.reward.banner.title')}
                    </Box>
                    <Box
                        component={Typography}
                        className="WP-daily-reward-banner-description"
                        variant={['p12', 'p12', 'p12', 'p16']}
                        fWeight="medium"
                        color={theme.palette.brown.A700}
                        mb={[2, 0]}
                    >
                        {t('daily.reward.banner.text')}
                    </Box>
                </Box>
            )}
            <Button
                onClick={showDailyLoginCalendar}
                variant="secondary"
                preventLoading={true}
                className="WP-calendar-banner-button"
                size="small"
                fullWidth={!breakpointsSM}
                shine
            >
                {t('daily.reward.banner.claim')}
            </Button>
        </Box>
    );
};

export default Unclaimed;
