import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import Claimed from './components/Claimed';
import Unclaimed from './components/Unclaimed';

import { DailyRewardBannerStyled } from './DailyRewardBanner.styled';
import { globalSel } from '../../../store/global';
import { dailyRewardsSel } from '../../../store/dailyReward';
import { getCookie } from '../../../bridge/cookieStorage';
import { shopSel } from '../../../store/shop';
import BannerSkeleton from './BannerSkeleton';
import { setGAEvent } from '../../../helpers/GA4Helper';

const DailyRewardBanner = ({ callback }) => {
    const isLoading = useSelector(shopSel.isLoading);
    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const calendarGifts = dailyRewards?.CalendarGifts;
    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;
    const flag = getCookie(`${currentUser?.UserId}_reward`);

    useEffect(() => {
        if (!isLoading && (calendarGifts?.length || !flag)) {
            setGAEvent('impression', {
                screen_id: 'web_store',
                screen_name: 'web_store',
                screen_type: 'screen',
                screen_category: 'daily_reward',
                interaction_type: 'load',
                interaction_object_category: 'display',
                flow_order: '0',
            });
        }
    }, [isLoading]);

    return (
        <Box
            className="WP-daily-reward-banner-container WP-hide-empty-space"
            pb={[10, 12, 12, 16, 20]}
        >
            {!calendarGifts?.length || flag || isLoading ? (
                <BannerSkeleton />
            ) : (
                <Box
                    component={DailyRewardBannerStyled}
                    minHeight={[100, 60]}
                    display="flex"
                    justifyContent="right"
                    borderRadius={12}
                    position="relative"
                >
                    {dailyGiftClaimed ? (
                        <Claimed time={dailyRewards?.NextClaimDate} callback={callback} />
                    ) : (
                        <Unclaimed />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default DailyRewardBanner;

DailyRewardBanner.propTypes = {
    isDailyRewardClaimed: PropTypes.bool,
    time: PropTypes.string,
    callback: PropTypes.string,
};
