import { createGlobalStyle } from 'styled-components';
import { icons } from './icons/icons';
import DiceBg from '../assets/images/Global/dicePattern.png';
import levelBadge from '../assets/images/UserProfile/levelBadge.png';

export const GlobalStyles = createGlobalStyle`
    ${icons}
    html {
        scrollbar-width: none;
    }

    body {
        min-height: 100vh;

        color: ${({ theme }) => theme.palette.brown[900]};
        font-family: ${({ theme }) => theme.typography.font.inter}, sans-serif;

        position: relative;
        background-color: #ffc352;
        margin: 0;

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 1;

            mix-blend-mode: multiply;
            background-image: url(${DiceBg});
            background-position: center;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                content: none;
            }
        }
        /*iframe {
            display: ${({ authenticated }) => (authenticated ? 'block' : 'none')} ;
        }*/
    }


    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    * {
        box-sizing: border-box;
    }

    a {
        transition: color 0.2s ease-out;
        text-decoration: none;
    }

    input {
        border: none;

        &:focus-visible {
            outline: none;
        }
    }

    ::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    .disable-scroll,
    .disable-scroll body,
    .disable-scroll .WP-layout {
        overflow: hidden;
        position: relative;
        touch-action: none;
        -ms-touch-action: none;
    }

    .hide {
        display: none;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .object-cover {
        object-fit: cover;
    }

    .object-contain {
        object-fit: contain;
    }

    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-truncate-second-line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .text-truncate-third-line {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .text-lowercase {
        text-transform: lowercase;
    }

    .text-capitalize {
        text-transform: capitalize;
    }

    .text-decoration-none {
        text-decoration: none;
    }

    .text-decoration-line-through {
        text-decoration: line-through;
    }

    .list-style-type-none {
        list-style-type: none;
    }

    .white-space-nowrap {
        white-space: nowrap;
    }

    .background-cover {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .user-select-none {
        user-select: none;
        cursor: default;
        pointer-events: none;
    }

    .touch-action-none {
        pointer-events: none;
        touch-action: none;
    }

    .display-block {
        display: block;
    }

    .fit-content {
        width: fit-content;
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-highlighted-black {
        -webkit-text-stroke: 5px #1F1F1F;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-highlighted-black-new {
        -webkit-text-stroke: 4px #1F1F1F;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-highlighted-gold {
        -webkit-text-stroke: 6px rgba(169, 64, 30, 0.8);
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-highlighted {
        -webkit-text-stroke: 6px rgba(169, 64, 30, 0.8);
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        ${({ theme }) => theme.breakpoints.down('md')} {
            -webkit-text-stroke: 4px rgba(169, 64, 30, 0.8);
        }
    }

    .WP-highlighted-gold {
        .text-highlighted {
            -webkit-text-stroke: 5px #1F1F1F;
            text-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        }

        .WP-highlighted-color {
            background: linear-gradient(180deg,#ffb03a 0%,#ffe51a 53.12%,#fff6b0 99.48%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .text-shadow {
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    }

    .WP-Calendar-Modal .MuiDialog-paper {
        max-width: unset;
        width: 100%;
        overflow: auto;
        background-color:  ${({ theme }) => theme.palette.orange[50]};
        margin: 16px;
        border-radius: 12px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: auto;
            margin: 32px;
            border-radius: 20px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            border-radius: 24px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            border-radius: 30px;
        }

    }

    .WP-Modal-dialog .MuiDialog-paper {
        max-width: unset;
        border-radius: 14px;
        background-color:  ${({ theme }) => theme.palette.orange[50]};
        overflow: hidden;
        overflow-y: auto;
        margin: 16px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: auto;
            border-radius: 20px;
            margin: 32px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: auto;
            border-radius: 24px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            width: auto;
            border-radius: 30px;
        }
    }

    .WP-modal-small {
        width: 100%;
        border-radius: 12px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 460px;
            border-radius: 20px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 570px;
            border-radius: 24px;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            width: 650px;
            border-radius: 30px;
        }
    }

    .WP-modal-medium {
        width: 100%;

          ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 650px;
          }
    }

    .WP-modal-lg {
        width: 100%;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            width: 460px;
            border-radius: 20px;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            width: 650px;
            border-radius: 30px;
        }
    }

    .WP-modal-gradient-background{
        position: relative;
    }
    .WP-modal-gradient-background::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient( rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 66%, rgba(0, 0, 0, 0.5) 100%);
        z-index: 1; 
    }

    @keyframes rotating {
        from{
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
        }
    }

    .WP-Button-Badge {
        width: 100%;

        position: absolute;
        bottom: calc(50% + 8px);
        left: 0;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            bottom: calc(50% + 12px);
        }
    }

    .WP-loading-icon-rotation {
        animation: rotating 1.5s linear infinite;
    }

    .WP-slider-spacing {
        margin: 0 -32px;
        padding: 0 32px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            margin: 0 -20px;
            padding: 0 20px;
        }
    }

    .WP-loyalty-point-page .WP-daily-deals {
        padding-top: 0;

    }

    .WP-featured:empty,
    .WP-loyalty-point-page .WP-daily-deals:empty{
        + section {
            padding-top: 0;
        }
    }

    .WP-featured,
    .WP-bonus-rolls,
    .WP-daily-deals,
    .WP-dice-more,
    .WP-stickers,
    .WP-loyalty-points,
    .WP-gift,
    .WP-energy,
    .WP-daily-offer{
        &:empty {
            padding-top: 0;
        }
    }

    .WP-hide-empty-space:empty {
        padding-bottom: 0;
    }

    //Tooltip
    .MuiTooltip-tooltip {
        width: 200px;

        color: ${({ theme }) => theme.palette.brown.main};
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        font-weight: 500;

        background-color: ${({ theme }) => theme.palette.common.white};
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        padding: 12px 8px;

        .MuiTooltip-arrow {
            color: ${({ theme }) => theme.palette.common.white};
        }
    }

    .WP-section-title{
        > i {
            ${({ theme }) => theme.breakpoints.up('lg')} {
                font-size: ${({ theme }) => theme.typography.size.h5.fontSize};
            }

            ${({ theme }) => theme.breakpoints.up('xl')} {
                font-size: 26px;
            }
        }
    }

    .hidden {
        display: none !important;
    }

    // joyride plugin styles override
    .react-joyride__overlay , .__floater__open {
        z-index: 1250 !important;
    }

    .__floater{
        ${({ theme }) => theme.breakpoints.down('xs')} {
            width: 90%;
        }
    }

    @media screen and (max-width: 767px) and (orientation: landscape) {
        #react-joyride-step-2 .__floater {
            width: 40%;
        }
    }

    .WP-ftue-tooltip {
        max-height: 200px;
        overflow-y: auto;
    }

    #react-joyride-step-2 {
        .__floater__arrow {
            svg {
                polygon {
                    fill: ${({ theme }) => theme.palette.orange.A50};
                }
            }
        }
    }

    #react-joyride-step-1 {
        .__floater__arrow {
            svg {
                polygon {
                    fill: #ef512f;

                    ${({ theme }) => theme.breakpoints.up('md')} {
                        fill: ${({ theme }) => theme.palette.orange.A50};
                    }
                }
            }
        }
    }

    .react-joyride__spotlight {
        border-radius: 14px!important;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            border-radius: 20px!important;
        }

        ${({ theme }) => theme.breakpoints.up('lg')} {
            border-radius: 24px!important;
        }

        ${({ theme }) => theme.breakpoints.up('xl')} {
            border-radius: 30px!important;
        }
    }

    .WP-daily-deals-ftue-position {
        position: relative;
        z-index: 100;

        @media screen and (max-width: 1279px) and (orientation: landscape) {
            z-index: 1100;
        }
    }

    .WP-level-badge {
        width: 52px;
        height: 52px;

        background-image: url(${levelBadge});
        background-size: cover;
        background-position: center;

        margin: 0 auto 12px;
    }

    .WP-card-modal-tooltip .MuiTooltip-tooltip{
        ${({ theme }) => theme.breakpoints.down('xs')} {
            width: 150px;
        }
    }

    .WP-mr-8 {
        margin-right: 8px;
    }

    .WP-mt-16 {
        margin-top: 16px;
    }

    .WP-mb-16 {
      margin-bottom: 16px;
    }

    .WP-minus-margin {
        margin-right: -20px;
        margin-left: -20px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            margin-right: -32px;
            margin-left: -32px;
        }
    }
    #ot-sdk-btn-floating {
        display: none;
    }

    .ot-sdk-show-settings {
        color: #844733;
        font-size: 14px;
    }

    #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
        padding: 0 !important;
        background-color: transparent;
        text-transform: capitalize;
        border: none !important;
        color: ${({ theme }) => theme.palette.brown.main} !important;
        font-size: 14px !important;
        &:hover {
            background-color: transparent !important;
        }
    }

    #onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
        background-color: #53B7FF !important;
        border-color: #53B7FF !important;
        border-radius: 10px !important;
    }
    #onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
        color: #53B7FF !important;
        border-color: #53B7FF !important;
    }
`;
