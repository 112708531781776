import styled from 'styled-components';

export const AddToHomeScreenStyled = styled.div`
    margin-bottom: 30px;

    .WP-add-to-home-screen-container {
        background: linear-gradient(180deg, #fff098 0%, #ffe980 100%);
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        padding: 10px 10px 10px 20px;
        gap: 15px;

        display: flex;
        justify-content: space-between;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            padding: 10px 10px 10px 12px;
        }

        .WP-add-to-home-screen-box {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -40%);
                width: 200%;
                height: auto;
                z-index: 0;
                opacity: 0.4;
            }
        }

        .WP-add-to-home-screen-text {
            text-align: center;
            font-weight: ${({ theme }) => theme.typography.weight.bold};
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
            color: ${({ theme }) => theme.palette.brown.main};
            z-index: 1;

            ${({ theme }) => theme.breakpoints.down('xs')} {
                max-width: 120px;
            }
        }
    }
`;
