import React from 'react';
import PropTypes from 'prop-types';

import { LinkStyled } from './Link.styled';
import { Icon } from 'portal-modules/core-ui';
import IconCustom from '../../IconCustom/IconCustom';

export const Link = ({
    size,
    variant,
    completed = false,
    fullWidth = true,
    className = '',
    icon,
    customIcon,
    children,
    disabled = false,
    hasDiscount = false,
    unAvailable,
    shine = false,
    ...rest
}) => {
    return (
        <LinkStyled
            fullWidth={fullWidth}
            className={`${shine && 'shine'} ${
                completed || unAvailable ? 'completed' : variant
            } ${size} ${className} cursor-pointer button-text ${
                hasDiscount ? 'discount-text' : ''
            }`}
            disabled={disabled || unAvailable}
            {...rest}
        >
            <>
                {icon && <Icon className={`${icon} button-icon`} />}
                {customIcon && (
                    <IconCustom
                        icon={customIcon}
                        className="WP-mr-8 button-icon custom-button-icon"
                    />
                )}
                {children}
            </>
        </LinkStyled>
    );
};

Link.defaultProps = {
    size: 'medium', // 'large' | 'medium' | 'small' | 'extra-small'
    hasDiscount: false, // is discount button with line through
    variant: 'primary', // 'primary' | 'secondary' | 'solid' | 'grey-1' |
    //'grey-2' | 'grey-3' | 'grey-4' | facebook-btn | 'outlined' | 'brown' | 'outlined-800'
    fullWidth: true, // 'fullWidth'
};

Link.propTypes = {
    size: PropTypes.string,
    variant: PropTypes.string,
    completed: PropTypes.bool,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    customIcon: PropTypes.string,
    disabled: PropTypes.bool,
    hasDiscount: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    preventLoading: PropTypes.bool,
    unAvailable: PropTypes.bool,
    propogate: PropTypes.bool,
    shine: PropTypes.bool,
};
