import styled from 'styled-components';
import sliderArrowLeftBrown from '../../../assets/images/Global/arrowLeftBrown.png';
import sliderArrowRightBrown from '../../../assets/images/Global/arrowRightBrown.png';
import sliderArrowLeft from '../../../assets/images/Global/arrowLeft.png';
import sliderArrowRight from '../../../assets/images/Global/arrowRight.png';

export const SwiperSliderStyled = styled.div`
    position: relative;

    .swiper-container {
        position: unset;
    }

    .swiper-button-prev,
    .swiper-button-next {
        z-index: 2;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        content: '';
        width: 40px;
        height: 40px;

        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 8;

        box-shadow: ${({ theme }) => theme.elevation[300]};
        background: ${({ theme }) => theme.palette.brown.A100} no-repeat center;
        background-size: 24px;
        border: 1px solid ${({ theme }) => theme.palette.brown.A50};
        border-radius: 50%;
    }

    .swiper-button-prev {
        left: -16px;

        &:after {
            left: 20px;
            background-image: url(${sliderArrowLeftBrown});
        }
    }

    .swiper-button-next {
        right: -16px;

        &:after {
            right: -22px;
            background-image: url(${sliderArrowRightBrown});
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-pagination {
        z-index: 8;
    }

    .swiper-pagination-bullets {
        width: auto;

        bottom: -32px;
        left: 50%;
        transform: translateX(-50%);

        margin: 0 auto;
        z-index: 1;

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;

            border: 3px solid #ffecd8;
            background-color: transparent;
            opacity: 1;

            &-active {
                background-color: #ffecd8;
            }

            ${({ theme }) => theme.breakpoints.up('md')} {
                width: 17px;
                height: 17px;
            }
        }
    }

    //Orange arrow start
    .orange-slider-arrows {
        overflow: unset;

        .swiper-slide {
            height: auto;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
            box-shadow: rgb(0 0 0 / 40%) 0 2px 14px 0;
            background: ${({ theme }) => theme.palette.orange.main} no-repeat center;
            background-size: 12px;
            border: 3px solid ${({ theme }) => theme.palette.common.white};
        }

        .swiper-button-prev {
            &:after {
                background-image: url(${sliderArrowLeft});
                background-position: 12px;
            }
        }

        .swiper-button-next {
            &:after {
                background-image: url(${sliderArrowRight});
                background-position: 15px;
                right: -26px;
            }
        }
    }
    //Orange arrow end
`;
