import styled from 'styled-components';
import bgImage from '../../../assets/images/DailyRewards/background.png';

export const DailyRewardsStyled = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .WP-daily-top-content {
        overflow-y: auto;
        flex: 1;
        background-color: #fffaf1;
    }

    // Header
    .WP-daily-header {
        background-image: url(${bgImage});
        background-size: cover;
        background-clip: border-box;

        padding: 50px 30px;
        display: flex;
        justify-content: center;

        img {
            width: 100%;
            max-width: 336px;

            ${({ theme }) => theme.breakpoints.up('sm')} {
                max-width: 506px;
            }
        }
    }

    // Body
    .WP-daily-body-container {
        padding-top: 8px;
        padding-bottom: 8px;
        gap: 8px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    // Gifts

    .WP-daily-body {
        position: relative;
        width: 100%;
        padding: 0px 100px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        ${({ theme }) => theme.breakpoints.down('xs')} {
            align-items: baseline;
            padding: 3px 10px;
        }
    }

    .WP-daily-body-tooltip {
        position: relative;

        .WP-tooltip {
            bottom: 100px;
            left: calc(50% - 100px);
            margin-left: 0;

            ::after {
                top: 99%;
                left: 50%;
            }
        }

        .WP-daily-body-box {
            position: relative;
            display: flex;
            flex-direction: column;

            border-radius: 18px;
            overflow: hidden;

            box-shadow: 0px 1.69px 1.69px 0px #00000040;

            .WP-daily-box-header {
                color: #fff;
                background-color: ${({ theme }) => theme.palette.orange.A800};

                padding: 8px 20px 10px 20px;
                font-size: 14px;
                font-weight: 700;

                ${({ theme }) => theme.breakpoints.up('sm')} {
                    padding: 8px 30px 10px 30px;
                    font-size: 22px;
                }
            }

            .WP-daily-box-content {
                position: relative;
                padding: 10px 0px 18px 0px;

                display: flex;
                justify-content: center;
                gap: 4px;

                ${({ theme }) => theme.breakpoints.up('sm')} {
                    padding: 20px 0px 30px 0px;
                }

                .WP-daily-box-content-item {
                    position: relative;

                    .WP-daily-box-content-background-available {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .WP-daily-box-content-item-image {
                        z-index: 1;
                        position: relative;
                    }

                    .WP-daily-box-content-item-quantity {
                        z-index: 1;
                        position: absolute;
                        left: 50%;
                        bottom: -6px;
                        transform: translateX(-50%);

                        ${({ theme }) => theme.breakpoints.up('sm')} {
                            bottom: -14px;
                        }
                    }
                }
            }

            &.claimed {
                opacity: 0.5;
                .WP-daily-box-header {
                    background-color: ${({ theme }) => theme.palette.green.A800};
                }
            }

            &.to-claim {
                .WP-daily-box-header {
                    background-color: ${({ theme }) => theme.palette.green.A800};
                }
            }
        }
    }

    .WP-daily-body-left-side {
        .WP-tooltip {
            left: 0;

            ::after {
                left: 20%;
            }
        }

        ${({ theme }) => theme.breakpoints.up('sm')} {
            .WP-tooltip {
                left: calc(50% - 100px);

                ::after {
                    left: 50%;
                }
            }
        }
    }
    .WP-daily-body-right-side {
        .WP-tooltip {
            left: auto;
            right: 0;

            ::after {
                left: auto;
                right: 25%;
            }
        }

        ${({ theme }) => theme.breakpoints.up('sm')} {
            .WP-tooltip {
                left: calc(50% - 100px);

                ::after {
                    left: 50%;
                    right: auto;
                }
            }
        }
    }

    .WP-daily-body-day7 {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }

    .WP-daily-body-day7-box {
        grid-column: 2 / 6;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
        .WP-daily-body {
            grid-template-columns: repeat(4, 1fr);
        }

        .WP-daily-body-day7 {
            grid-column: 3 / 5;
        }

        .WP-daily-body-day7-box {
            grid-column: 1 / -1;
        }
    }

    // Footer
    .WP-daily-footer {
        background-color: #fffaf1;
        padding-top: 12px;
        z-index: 1;
        position: sticky;
        bottom: 0;
    }
`;
