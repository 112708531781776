import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { Box, Icon } from 'portal-modules/core-ui';
import { Dialog, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import PaymentModal from '../components/PaymentModal/PaymentModal';

import DailyRewards from '../../DailyReward/DailyRewards';
import {
    DAILY_GIFT_MODAL,
    DAILY_GIFT_MODAL_DATA,
    DAILY_LOGIN_CALENDAR_MODAL,
    DAILY_OFFER_CALENDAR_MODAL,
} from '../../../../constants/modals.constants';

import { dailyOffersSel } from '../../../../store/dailyOffers';
import { globalOp, globalSel } from '../../../../store/global';
import { dailyRewardsOp, dailyRewardsSel } from '../../../../store/dailyReward';

import { setGAEvent } from '../../../../helpers/GA4Helper';
import { DailyCalendarStyled } from './DailyCalendar.styled';

const DailyLoginCalendarModal = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const location = useLocation();

    const modal = useSelector(dailyRewardsSel.modalSelector);
    const dailyOffers = useSelector(dailyOffersSel.dailyOffers);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);
    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);

    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;
    const dailyProgress = dailyRewards?.DailyProgress;

    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    useLayoutEffect(() => {
        const html = document.getElementsByTagName('html')[0].classList;

        if (modal.open && breakpointsXS) {
            html.add('disable-scroll');
        } else {
            html.remove('disable-scroll');
        }
    }, [modal.open]);

    const handleDailyOffer = () => {
        dailyOffers?.[0] &&
            location?.pathname === '/store' &&
            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: DAILY_OFFER_CALENDAR_MODAL,
                }),
            );
    };

    const modalGenerator = () => {
        const { variant, isDailyRewardCompleted } = modal;

        switch (variant) {
            case DAILY_GIFT_MODAL:
                return (
                    <PaymentModal
                        theme={theme}
                        type={DAILY_GIFT_MODAL}
                        data={DAILY_GIFT_MODAL_DATA}
                        isDailyRewardCompleted={isDailyRewardCompleted}
                        callback={handleDailyOffer}
                    />
                );
            case DAILY_LOGIN_CALENDAR_MODAL:
                return <DailyRewards theme={theme} />;
            default:
                return null;
        }
    };

    const handleClose = (variant) => {
        if (variant === 'daily_login_calendar_modal') {
            if (dailyGiftClaimed) {
                setGAEvent('clickEvent', {
                    screen_name: 'daily_login_reward',
                    screen_type: 'pop-up',
                    interaction_object: 'close_button',
                    interaction_object_category: 'close',
                    offer_id: dailyGiftClaimed ? `D${dailyProgress}` : `D${dailyProgress + 1}`,
                });

                return dispatch(dailyRewardsOp.handleCloseModal());
            } else {
                dispatch(dailyRewardsOp.handleCloseModal());
                // dispatch(dailyRewardsOp.claimDailyRewards(true));
            }
        } else {
            if (dailyOffers?.[0] && location?.pathname === '/store') {
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: DAILY_OFFER_CALENDAR_MODAL,
                    }),
                );
            }

            dispatch(dailyRewardsOp.handleCloseModal());
        }
    };

    return (
        modal.open &&
        !maintenanceMode &&
        typeof dailyRewards !== 'undefined' && (
            <Dialog
                fullWidth={false}
                maxWidth="sm"
                open={modal.open}
                className="WP-Calendar-Modal"
                onClose={() => handleClose(modal.variant)}
            >
                <Box component={DailyCalendarStyled} className="WP-Modal-root flex-center">
                    <Box
                        position="absolute"
                        top={12}
                        right={12}
                        onClick={() => handleClose(modal.variant)}
                        data-testid="modal-close-test-id"
                    >
                        <Box
                            component={Icon}
                            size={18}
                            className={clsx(
                                'icon-close',
                                'cursor-pointer',
                                'WP-Modal-close-button flex-center',
                            )}
                            borderRadius="50%"
                        />
                    </Box>

                    {modalGenerator(modal.variant)}
                </Box>
            </Dialog>
        )
    );
};

export default DailyLoginCalendarModal;
