import { theme } from '../../theme/themeMUI';
import { LANGUAGES } from '../../constants/languages.constantants';

export function initialGlobalState() {
    return {
        theme: { ...theme },
        language: LANGUAGES[0],
        authenticated: false,
        scopelyLogin: false,
        fbLogin: false,
        currentUser: null,
        currentLevel: null,
        isLoading: false,
        isProfileLoading: false,
        globalLeaderboards: null,
        isLeaderboardsLoading: true,
        notFound: false,
        tabsLoading: true,
        configs: {
            appLoaded: false,
            IsUnderMaintenance: false,
        },
        snackbar: {
            open: false,
            variant: '',
        },
        modal: {
            open: false,
            variant: '',
        },
        interstitials: {
            open: false,
        },
        fbData: null,
        popUps: {},
        loggedIn: false,
        showTour: false,
        gameConfig: {
            data: undefined,
            error: undefined,
            status: 'idle',
        },
    };
}
