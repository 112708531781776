import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from '../../../../hooks/useTranslation';
import { DAILY_LOGIN_CALENDAR_MODAL } from '../../../../constants/modals.constants';
import { dailyRewardsOp } from '../../../../store/dailyReward';
import { Button } from '../../ui-components/Button/Button';
import { setGAEvent } from '../../../../helpers/GA4Helper';

const Claimed = ({ callback, time }) => {
    const theme = useTheme();
    const t = useTranslation();
    const dispatch = useDispatch();
    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

    const showDailyLoginCalendar = () => {
        setGAEvent('clickEvent', {
            screen_id: 'web_store',
            screen_name: 'web_store',
            screen_type: 'screen',
            screen_category: 'daily_reward',
            interaction_type: 'tap',
            interaction_object: 'view_calendar',
            interaction_object_category: 'display',
            flow_order: '0',
        });
        dispatch(
            dailyRewardsOp.handleOpenModal({
                open: true,
                variant: DAILY_LOGIN_CALENDAR_MODAL,
            }),
        );
    };

    return (
        <Box className="WP-calendar-banner-container" width="70%">
            <Box
                component={Typography}
                variant="p16"
                fWeight="bold"
                color={theme.palette.brown.main}
                maxWidth={230}
                mb={[2, 0]}
            >
                {t('daily.reward.banner.description')}
            </Box>

            <Button
                onClick={showDailyLoginCalendar}
                variant="outlined-800"
                preventLoading={true}
                className="WP-calendar-banner-button"
                size="small"
                fullWidth={!breakpointsSM}
            >
                {t('daily.reward.banner.view')}
            </Button>
        </Box>
    );
};

export default Claimed;

Claimed.propTypes = {
    time: PropTypes.string,
    callback: PropTypes.string,
};
