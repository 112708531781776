import ChristmasGetEverythingImage from '../../../../assets/images/LatestUpdates/2024-christmas/christmas-get-everything.png';
import ChristmasRedeemLoyaltyPointsImage from '../../../../assets/images/LatestUpdates/2024-christmas/christmas-redeem-loyalty-points.png';
import ChristmasSaleImage from '../../../../assets/images/LatestUpdates/2024-christmas/christmas-sale.png';

const christmasLatestUpdates = [
    {
        Status: 0,
        Config: {
            Id: '5c4b86f2-9244-41bb-9a38-41f88c43e091',
            Type: 0,
            Title: 'FULL IMAGE - VARIANT A',
            SubTitle: '',
            Category: {
                Name: 'test1',
                ColorHex: '#fffffa',
            },
            Description: 'TEST FOR CARROUSEL CARD',
            Ctas: [],
            Content: {
                OverlayUrl: ChristmasGetEverythingImage,
                Blocks: [],
                Ctas: [
                    {
                        Type: 1,
                        Text: 'To the Loyalty Points!',
                        Link: '/loyalty-point-shop',
                    },
                ],
                Rewards: [],
            },
            StartDate: '2023-12-29T17:26:00',
            EndDate: '2024-09-21T13:51:00',
            Order: 0,
            CtaPlacement: 1,
        },
    },
    {
        Status: 0,
        Config: {
            Id: '0776ded4-2ce1-40ad-a6f6-c6db7dd14575',
            Type: 0,
            Title: 'IMAGE AND TEXT - VARIANT A',
            SubTitle: '',
            Category: {
                Name: 'test1',
                ColorHex: '#fffffa',
            },
            Description: 'TEST FOR CARROUSEL CARD',
            Ctas: [],
            Content: {
                Blocks: [
                    {
                        Heading: 'Discover the Loyalty Points Shop',
                    },
                    {
                        Body: '',
                        ImageUrl: ChristmasRedeemLoyaltyPointsImage,
                    },
                    {
                        Body: 'Points can be spent in the Shop!  There are always new Rewards to choose from!',
                    },
                ],
                Ctas: [
                    {
                        Type: 1,
                        Text: 'Check it Out!',
                        Link: '/loyalty-point-shop',
                    },
                ],
                Rewards: [],
            },
            StartDate: '2023-12-29T17:26:00',
            EndDate: '2024-09-21T13:51:00',
            Order: 1,
            CtaPlacement: 1,
        },
    },
    {
        Status: 0,
        Config: {
            Id: 'b5e7364a-3049-4af2-ae13-ac1eaafe063a',
            Type: 0,
            Title: 'FULL IMAGE - VARIANT A',
            SubTitle: '',
            Category: {
                Name: 'test1',
                ColorHex: '#fffffa',
            },
            Description: 'TEST FOR CARROUSEL CARD',
            Ctas: [],
            Content: {
                OverlayUrl: ChristmasSaleImage,
                Blocks: [],
                Ctas: [
                    {
                        Type: 2,
                        Text: 'Buy Now!',
                        Link: '/store',
                    },
                ],
                Rewards: [],
            },
            StartDate: '2023-12-29T17:26:00',
            EndDate: '2024-09-21T13:51:00',
            Order: 0,
            CtaPlacement: 1,
        },
    },
];

export { christmasLatestUpdates };
