import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'portal-modules/core-ui';

import { StepsTutorialModalStyled } from './StepsTutorialModal.styled';

import { setGAEvent } from '../../../../../helpers/GA4Helper.js';

const StepsTutorialModal = ({ theme, data = {} }) => {
    useEffect(() => {
        if (data.impressionEvent) {
            setGAEvent('clickEvent', data.impressionEvent);
        }

        let timeoutId;

        const handleScroll = () => {
            if (data.scrollEvent) {
                // send scroll event
                clearTimeout(timeoutId);
                timeoutId = setTimeout(setGAEvent('clickEvent', data.scrollEvent), 200);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const formatText = (text) => {
        const regex = /\$\{(.*?)\}/g;
        const parts = text.split(regex);

        return parts.map((part, index) => {
            if (part.match(/^\w+$/)) {
                return (
                    <img
                        key={index}
                        src={
                            require(`../../../../../assets/images/Modal/StepsTutorial/${part}.png`)
                                .default
                        }
                        alt={part}
                    />
                );
            }
            return part;
        });
    };

    return (
        <Box
            component={StepsTutorialModalStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className="WP-leaders-modal"
        >
            <Box className="WP-loyalty-points-learn-cards">
                {data.title && <h6 className="WP-loyalty-points-learn-card-title">{data.title}</h6>}
                {data.steps.map((step, index) => (
                    <Box className="WP-loyalty-points-learn-card" key={`step-${index}`}>
                        <img
                            src={step.image}
                            className="WP-loyalty-points-learn-card-image"
                            alt={`step-${index}-image`}
                        />
                        {step.title && (
                            <h6 className="WP-loyalty-points-learn-card-title">{step.title}</h6>
                        )}
                        <p className="WP-loyalty-points-learn-card-text">
                            {formatText(step.subtitle)}
                        </p>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

StepsTutorialModal.propTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
};

export default StepsTutorialModal;
