import * as React from 'react';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { useTranslation } from '../../../hooks/useTranslation';
import DangerouslyHighlightedText from '../DangerouslyHighlightedText/DangerouslyHighlightedText';

import { AccordionStyled } from './Accordion.styled';

import IconArrow from '../../../assets/images/Home/Logout/arrow';

const Accordion = withStyles({
    root: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        marginBottom: 8,
        overflow: 'hidden',
        position: 'relative',
        '&:first-child': {
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
        },
        '&:last-child': {
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            marginBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#eadbc2',
        padding: '6px 12px',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        margin: 0,
        paddingRight: 12,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        backgroundColor: '#F6EDDC',
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        padding: 12,
        marginBottom: 8,
    },
})(MuiAccordionDetails);

const CustomAccordion = () => {
    const t = useTranslation();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box component={AccordionStyled}>
            <Box className="WP-accordion-title" display="flex" justifyContent="center" mb={4}>
                <DangerouslyHighlightedText
                    className="WP-accordion-highlighted"
                    text="ANY OPEN QUESTION?" //TODO: t('faq.section.header')
                />
            </Box>

            <Box className="WP-accordion-wrapper">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<IconArrow />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="WP-accordion-header"
                    >
                        <Box component="p" className="WP-accordion-text">
                            {t('faq.question.1')}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            component="p"
                            className="WP-accordion-content"
                            dangerouslySetInnerHTML={{
                                __html: t('faq.description.1'),
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<IconArrow />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="WP-accordion-header"
                    >
                        <Box component="p" className="WP-accordion-text">
                            {t('faq.question.2')}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component="p" className="WP-accordion-content">
                            {t('faq.description.2')}
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<IconArrow />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                        className="WP-accordion-header"
                    >
                        <Box component="p" className="WP-accordion-text">
                            {t('faq.question.3')}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            component="p"
                            className="WP-accordion-content"
                            dangerouslySetInnerHTML={{
                                __html: t('faq.description.3'),
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<IconArrow />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        className="WP-accordion-header"
                    >
                        <Box component="p" className="WP-accordion-text">
                            {t('faq.question.4')}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component="p" className="WP-accordion-content">
                            {t('faq.description.4')}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};
export default CustomAccordion;
