import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { SwiperSliderStyled } from './SwiperSlider.styles';

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

type SwiperSliderProps = Swiper;

export default function SwiperSlider(props: SwiperSliderProps) {
    return (
        <SwiperSliderStyled>
            <Swiper {...props}>
                {Array.isArray(props.children) &&
                    props.children.map((slide, index) => {
                        return <SwiperSlide key={index}>{slide}</SwiperSlide>;
                    })}
            </Swiper>
        </SwiperSliderStyled>
    );
}
