import React, { Fragment } from 'react';

import SwiperSlider from '../SwiperSlider/SwiperSlider';
import FeaturedSliderItem from './FeaturedSliderItem';

type StoreListing = {
    Name: string;
    StoreListing: Record<any, any>;
};

type FeaturedSliderRedesignedProps = {
    data: StoreListing[];
    endDate: string;
};

const DELAY = 5000;

function FeaturedSliderRedesigned({ data, endDate }: FeaturedSliderRedesignedProps) {
    if (!data) return null;

    return (
        <SwiperSlider
            data-testid="featured-slider-redesigned"
            navigation={false}
            spaceBetween={16}
            className="WP-slider-spacing wp-featured-slider-redesigned"
            pagination={{ clickable: false }}
            allowTouchMove
            autoplay={{ delay: DELAY, disableOnInteraction: false, stopOnLastSlide: false }}
            centeredSlides={data.length === 1}
            breakpoints={{
                300: {
                    slidesPerView: 1.15,
                },
                400: {
                    slidesPerView: 1.11,
                },
                768: {
                    slidesPerView: 1.07,
                },
                1024: {
                    slidesPerView: 1.05,
                },
                1280: {
                    slidesPerView: 1.04,
                },
                1440: {
                    slidesPerView: 1.037,
                },
                1920: {
                    slidesPerView: 1.035,
                },
            }}
        >
            {data.map((element, index) => {
                const sliderItem =
                    element?.StoreListing?.availableCount <= 0 &&
                    element?.StoreListing?.FallbackListing
                        ? element?.StoreListing?.FallbackListing
                        : element?.StoreListing;

                return (
                    <Fragment key={`${element?.Name ?? ''}-${index}`}>
                        <FeaturedSliderItem
                            item={sliderItem}
                            commodity={element?.StoreListing?.Debit?.Adjustments[0].CommodityKey}
                            endDate={endDate}
                        />
                    </Fragment>
                );
            })}
        </SwiperSlider>
    );
}

export default FeaturedSliderRedesigned;
