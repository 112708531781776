import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { Box, Icon } from 'portal-modules/core-ui';
import { Dialog } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import OfferCardModal from './components/OfferCardModal/OfferCardModal';
import PaymentModal from './components/PaymentModal/PaymentModal';
import Processing from './components/ProcessingModal/Processing';
import InsufficientModal from './components/InsufficientModal/InsufficientModal';
import InterstitialLogoutModal from './components/InterstitialLogout/InterstitialLogout';
import Welcome from './components/WelcomeModal/Welcome';
import DailyOffer from './components/DailyOffers/DailyOffer';
import TopLeadersModal from './components/TopLeadersModal/TopLeadersModal';
import StepsTutorialModal from './components/StepsTutorialModal/StepsTutorialModal';

import { ModalStyled } from './Modal.styled';
import { globalOp, globalSel } from '../../../store/global';
import {
    OFFER_CARD_MODAL,
    LOGIN_MODAL,
    PAYMENT_SUCCESS_MODAL,
    PAYMENT_FAILURE_MODAL,
    REFRESH_FAILURE_MODAL,
    ACCOUNT_SUSPENDED_MODAL,
    ACCOUNT_FAILED_MODAL,
    OFFER_UNAVAILABLE_MODAL,
    DAILY_GIFT_MODAL,
    INSUFFICIENT_MODAL,
    INTERSTITIAL_LOGOUT_MODAL,
    WELCOME_MODAL,
    DAILY_OFFER_CALENDAR_MODAL,
    TOP_LEADERS_MODAL,
    STEPS_TUTORIAL_MODAL,
    NO_CONNECTED_FB_ACCOUNT_MODAL,
    NO_CONNECTED_SCOPELY_ACCOUNT_MODAL,
    LOYALTY_POINTS_TRANSITION_MODAL,
    DAILY_DEALS_REFRESH_LIMIT_MODAL,
    DAILY_DEALS_REFRESH_EXPIRED_MODAL,
    FACEBOOK_OPEN_TAB_REMINDER,
} from '../../../constants/modals.constants';
import { removeStorageItem } from '../../../helpers/localStorage';
import LoyaltyPointsTransitionModal from './components/LoyaltyPointsNewLook/LoyaltyPointsNewLookModal';
import { setGAEvent } from '../../../helpers/GA4Helper.js';

const Modal = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const modal = useSelector(globalSel.modalSelector);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);

    const modalGenerator = () => {
        const {
            variant,
            data,
            section,
            isEarnExist,
            isBonusRollsDetails,
            isDiceAndMoreDetails,
            message,
            isLogOutState,
        } = modal;
        switch (variant) {
            case OFFER_CARD_MODAL:
                return (
                    <OfferCardModal
                        theme={theme}
                        variant={variant}
                        data={data}
                        section={section}
                        isEarnExist={isEarnExist}
                        isBonusRollsDetails={isBonusRollsDetails}
                        isDiceAndMoreDetails={isDiceAndMoreDetails}
                    />
                );
            case LOGIN_MODAL:
                return <Processing theme={theme} data={modal.data} isLogOutState={isLogOutState} />;
            case PAYMENT_SUCCESS_MODAL:
                return (
                    <PaymentModal
                        theme={theme}
                        type={PAYMENT_SUCCESS_MODAL}
                        message={message}
                        data={modal.data}
                    />
                );
            case PAYMENT_FAILURE_MODAL:
                return (
                    <PaymentModal theme={theme} type={PAYMENT_FAILURE_MODAL} data={modal.data} />
                );
            case REFRESH_FAILURE_MODAL:
                return (
                    <PaymentModal theme={theme} type={REFRESH_FAILURE_MODAL} data={modal.data} />
                );
            case DAILY_GIFT_MODAL:
                return <PaymentModal theme={theme} type={DAILY_GIFT_MODAL} data={modal.data} />;
            case ACCOUNT_SUSPENDED_MODAL:
                return <Processing theme={theme} data={modal.data} />;
            case ACCOUNT_FAILED_MODAL:
                return <Processing theme={theme} data={modal.data} />;
            case OFFER_UNAVAILABLE_MODAL:
                return <Processing theme={theme} data={modal.data} />;
            case NO_CONNECTED_FB_ACCOUNT_MODAL:
                return <Processing theme={theme} data={modal.data} isNoConnectedFb />;
            case NO_CONNECTED_SCOPELY_ACCOUNT_MODAL:
                return <Processing theme={theme} data={modal.data} isNoConnectedScopely />;
            case INSUFFICIENT_MODAL:
                return <InsufficientModal theme={theme} />;
            case INTERSTITIAL_LOGOUT_MODAL:
                return <InterstitialLogoutModal theme={theme} />;
            case WELCOME_MODAL:
                return <Welcome theme={theme} />;
            case DAILY_OFFER_CALENDAR_MODAL:
                return <DailyOffer theme={theme} data={data} />;
            case TOP_LEADERS_MODAL:
                return <TopLeadersModal theme={theme} data={modal.data} />;
            case STEPS_TUTORIAL_MODAL:
                return <StepsTutorialModal theme={theme} data={modal.data} />;
            case LOYALTY_POINTS_TRANSITION_MODAL:
                return <LoyaltyPointsTransitionModal theme={theme} />;
            case DAILY_DEALS_REFRESH_LIMIT_MODAL:
                return <Processing theme={theme} data={modal.data} />;
            case DAILY_DEALS_REFRESH_EXPIRED_MODAL:
                return <Processing theme={theme} data={modal.data} />;
            case FACEBOOK_OPEN_TAB_REMINDER:
                return <Processing theme={theme} data={modal.data} isNoConnectedFb />;
            default:
                return null;
        }
    };

    const html = document.getElementsByTagName('html')[0].classList;

    const handleClosePopups = (variant) => {
        switch (variant) {
            case 'payment-process' || 'reward-process':
                html.remove('disable-scroll');
                return () => {};
            case WELCOME_MODAL: {
                dispatch(globalOp.getLoyaltyPoints());
                return dispatch(globalOp.handleCloseModal());
            }
            case LOGIN_MODAL: {
                removeStorageItem('claimed_clicked');
                return dispatch(globalOp.handleCloseModal());
            }
            case STEPS_TUTORIAL_MODAL: {
                if (modal.data?.closeEvent) {
                    setGAEvent('clickEvent', modal.data.closeEvent);
                }
                return dispatch(globalOp.handleCloseModal());
            }
            default:
                html.remove('disable-scroll');
                return dispatch(globalOp.handleCloseModal());
        }
    };

    return (
        modal.open &&
        !maintenanceMode && (
            <Dialog
                fullWidth={false}
                maxWidth="sm"
                open={modal.open}
                className="WP-Modal-dialog"
                onClose={() => handleClosePopups(modal.variant)}
            >
                <Box
                    component={ModalStyled}
                    className="WP-Modal-root flex-center"
                    width="100%"
                    position="relative"
                    flexDirection="column"
                >
                    {modal.variant !== 'payment-process' && modal.variant !== 'reward-process' && (
                        <Box
                            position="absolute"
                            top={12}
                            right={12}
                            onClick={() => handleClosePopups(modal.variant)}
                            data-testid="modal-close-data-test-id"
                        >
                            <Box
                                component={Icon}
                                size={18}
                                className={clsx(
                                    'icon-close',
                                    'cursor-pointer',
                                    'WP-Modal-close-button flex-center',
                                )}
                                borderRadius="50%"
                            />
                        </Box>
                    )}

                    {modalGenerator(modal.variant)}
                </Box>
            </Dialog>
        )
    );
};

export default Modal;
