import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AddToHomeScreenStyled } from './AddToHomeScreen.styled';

import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '../ui-components/Button/Button';
import { globalOp } from '../../../store/global';
import { shopSel } from '../../../store/shop';
import { STEPS_TUTORIAL_MODAL } from '../../../constants/modals.constants';
import { useTranslation } from '../../../hooks/useTranslation';
import { setGAEvent } from '../../../helpers/GA4Helper.js';
import { detectOs } from '../../../helpers/detectOs.js';

import athWebPortal from '../../../assets/images/Modal/StepsTutorial/ATHWebPortal.png';
import athGetIt from '../../../assets/images/Modal/StepsTutorial/ATHGetIt.png';
import athEnjoy from '../../../assets/images/Modal/StepsTutorial/ATHEnjoy.png';
import athBackgroundButton from '../../../assets/images/Modal/StepsTutorial/ATHBackgroundButton.png';

import AddToHomeScreenSkeleton from './AddToHomeScreenSkeleton';

const AddToHomeScreen = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const t = useTranslation();

    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    const isLoading = useSelector(shopSel.isLoading);

    const os = detectOs();

    useEffect(() => {
        setGAEvent('impression', {
            screen_id: 'add_to_home_screen',
            screen_name: 'add_to_home_screen',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'impression',
        });
    }, []);

    const handleAddToHomeScreenButton = () => {
        setGAEvent('clickEvent', {
            screen_id: 'add_to_home_screen',
            screen_name: 'add_to_home_screen',
            screen_type: 'screen',
            screen_section: 'web',
            interaction_type: 'tap',
            interaction_object_category: 'display',
        });

        dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: STEPS_TUTORIAL_MODAL,
                data: {
                    title: t('add.to.home.title'),
                    steps: [
                        {
                            image: athWebPortal,
                            subtitle: t('add.to.home.text.portal.' + os),
                        },
                        {
                            image: athGetIt,
                            subtitle: t('add.to.home.text.get.' + os),
                        },
                        {
                            image: athEnjoy,
                            subtitle: t('add.to.home.text.enjoy'),
                        },
                    ],
                    impressionEvent: {
                        screen_id: 'ftue_add_home_screen',
                        screen_name: 'ftue_add_home_screen',
                        screen_type: 'pop-up',
                        screen_section: 'web',
                        interaction_type: 'impression',
                    },
                    closeEvent: {
                        screen_id: 'ftue_add_home_screen',
                        screen_name: 'ftue_add_home_screen',
                        screen_type: 'pop-up',
                        screen_section: 'web',
                        interaction_type: 'tap',
                        interaction_object: 'close',
                        interaction_object_category: 'display',
                        flow_order: 1,
                    },
                },
            }),
        );
    };

    return (
        <Box component={AddToHomeScreenStyled}>
            {isLoading ? (
                <AddToHomeScreenSkeleton />
            ) : (
                <Box className="WP-add-to-home-screen-container">
                    <Box className="WP-add-to-home-screen-box">
                        <span className="WP-add-to-home-screen-text">
                            {t('add.to.home.banner')}
                        </span>
                    </Box>
                    <Box className="WP-add-to-home-screen-box">
                        <img src={athBackgroundButton} alt="button-light-background" />
                        <Button
                            onClick={handleAddToHomeScreenButton}
                            size={breakpointsXS ? 'small' : 'medium'}
                            variant="primary"
                            preventLoading={true}
                            fullWidth={false}
                        >
                            {t('add.to.home.button')}
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AddToHomeScreen;
