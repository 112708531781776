import kingdomEventIsHere from '../../../../assets/images/LatestUpdates/KingdomEventIsHere.png';
import redeemYourLoyaltyPoints from '../../../../assets/images/LatestUpdates/RedeemYourLoyaltyPoints.png';
import getVeryBestPrices from '../../../../assets/images/LatestUpdates/GetVeryBestPrices.png';

const standardLatestUpdates = [
    {
        Status: 0,
        Config: {
            Id: '3302a66e-9b3d-43a1-8aca-8d2ac4df6960',
            Type: 0,
            Title: 'FULL IMAGE - VARIANT A',
            SubTitle: '',
            Category: {
                Name: 'test1',
                ColorHex: '#fffffa',
            },
            Description: 'TEST FOR CARROUSEL CARD',
            Ctas: [],
            Content: {
                OverlayUrl: redeemYourLoyaltyPoints,
                Blocks: [],
                Ctas: [
                    {
                        Type: 1,
                        Text: 'Loyalty Pays Off!',
                        Link: '/loyalty-point-shop',
                    },
                ],
                Rewards: [],
            },
            StartDate: '2023-12-29T17:26:00',
            EndDate: '2024-09-21T13:51:00',
            Order: 0,
            CtaPlacement: 1,
        },
    },
    {
        Status: 0,
        Config: {
            Id: '0f643a1c-0ac6-469a-85e0-913aeba07878',
            Type: 0,
            Title: 'IMAGE AND TEXT - VARIANT A',
            SubTitle: '',
            Category: {
                Name: 'test1',
                ColorHex: '#fffffa',
            },
            Description: 'TEST FOR CARROUSEL CARD',
            Ctas: [],
            Content: {
                Blocks: [
                    {
                        Heading: 'Discover the Loyalty Points Shop',
                    },
                    {
                        Body: '',
                        ImageUrl: kingdomEventIsHere,
                    },
                    {
                        Body: 'Points can be spent in the Shop!  There are always new Rewards to choose from!',
                    },
                ],
                Ctas: [
                    {
                        Type: 1,
                        Text: 'Check it Out!',
                        Link: '/loyalty-point-shop',
                    },
                ],
                Rewards: [],
            },
            StartDate: '2023-12-29T17:26:00',
            EndDate: '2024-09-21T13:51:00',
            Order: 1,
            CtaPlacement: 1,
        },
    },
    {
        Status: 0,
        Config: {
            Id: '8de70488-1f8e-4b8c-bec0-b87d1c9fd1b7',
            Type: 0,
            Title: 'FULL IMAGE - VARIANT A',
            SubTitle: '',
            Category: {
                Name: 'test1',
                ColorHex: '#fffffa',
            },
            Description: 'TEST FOR CARROUSEL CARD',
            Ctas: [],
            Content: {
                OverlayUrl: getVeryBestPrices,
                Blocks: [],
                Ctas: [
                    {
                        Type: 2,
                        Text: 'Buy Now!',
                        Link: '/store',
                    },
                ],
                Rewards: [],
            },
            StartDate: '2023-12-29T17:26:00',
            EndDate: '2024-09-21T13:51:00',
            Order: 0,
            CtaPlacement: 1,
        },
    },
];

export { standardLatestUpdates };
