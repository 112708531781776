export const PAYMENT_SUCCESS_MODAL = 'success';
export const PAYMENT_FAILURE_MODAL = 'failure';
export const REFRESH_FAILURE_MODAL = 'refresh_failure';
export const DAILY_GIFT_MODAL = 'gift';
export const OFFER_CARD_MODAL = 'offer_card';
export const ACCOUNT_SUSPENDED_MODAL = 'account_suspended';
export const ACCOUNT_FAILED_MODAL = 'account_failed';
export const OFFER_UNAVAILABLE_MODAL = 'offer_unavailable';
export const INSUFFICIENT_MODAL = 'insufficient ';
export const NEWS_MODAL = 'news_modal';
export const LOGIN_MODAL = 'login';
export const INTERSTITIAL_LOGOUT_MODAL = 'interstitial_logout_modal';
export const WELCOME_MODAL = 'welcome_modal';
export const DAILY_LOGIN_CALENDAR_MODAL = 'daily_login_calendar_modal';
export const DAILY_OFFER_CALENDAR_MODAL = 'daily_offer_calendar_modal';
export const TOP_LEADERS_MODAL = 'top_leaders';
export const STEPS_TUTORIAL_MODAL = 'steps_tutorial_modal';
export const LOYALTY_POINTS_TRANSITION_MODAL = 'loyalty_points_transition';
export const DAILY_DEALS_REFRESH_LIMIT_MODAL = 'daily_deals_refresh_limit';
export const DAILY_DEALS_REFRESH_EXPIRED_MODAL = 'daily_deals_refresh_expired';

export const PAYMENT_SUCCESS_MODAL_DATA = {
    title: 'gift.popup.title',
    description: 'gift.popup.description',
    image: 'gift',
};

export const PAYMENT_FAILURE_MODAL_DATA = {
    title: 'failure.popup.title',
    description: 'failure.popup.description',
    image: 'failure',
};

export const REFRESH_FAILURE_MODAL_DATA = {
    title: 'refresh.failure.title',
    description: 'refresh.failure.description',
    image: 'failure',
};

export const DAILY_GIFT_MODAL_DATA = {
    title: 'gift.popup.title',
    description: 'gift.popup.description',
    image: 'gift',
};

export const NO_CONNECTED_FB_ACCOUNT_MODAL = {
    title: 'one.more.step',
    description: 'one.more.facebook.account.description',
    subDescription: 'one.more.scopely.account.subdescription',
    buttonText: 'connect.now',
    className: 'WP-daily-gift-modal',
    redirectTo: 'yux-settings',
};

export const NO_CONNECTED_SCOPELY_ACCOUNT_MODAL = {
    title: 'one.more.step',
    description: 'one.more.scopely.account.description',
    subDescription: 'one.more.scopely.account.subdescription',
    buttonText: 'connect.now',
    className: 'WP-daily-gift-modal',
    redirectTo: 'yux-settings',
};

export const OFFER_UNAVAILABLE_MODAL_DATA = {
    image: 'gift',
    title: 'daily.gift.title',
    description: 'daily.gift.description',
    buttonText: 'return.to.store',
    className: 'WP-daily-gift-modal',
    redirectTo: 'close',
    dataTestId: 'daily-gift-return-to-store-test-id',
};

export const DAILY_DEALS_REFRESH_LIMIT_MODAL_DATA = {
    image: 'failure',
    title: 'daily.deals.refresh.limit.title',
    description: 'daily.deals.refresh.limit.body',
    buttonText: 'close',
    redirectTo: 'close',
};

export const FACEBOOK_OPEN_TAB_REMINDER = {
    title: 'facebook.open.tab.reminder.title',
    description: 'facebook.open.tab.reminder.title.description',
    className: 'WP-daily-gift-modal',
};

export const DAILY_DEALS_REFRESH_EXPIRED_MODAL_DATA = {
    image: 'loyaltyPoint',
    title: 'daily.deals.refresh.expired.title',
    buttonText: 'show.me',
    redirectTo: 'refresh',
};
