import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Loader from './Loader';
import { Store } from './store/configureStore';
import { ErrorBoundary } from './ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';
import {
    DATADOG_RUM_APP_ID,
    DATADOG_RUM_CLIENT_TOKEN,
    DATADOG_RUM_SERVICE,
} from './constants/monitors.constants';
import App from './App';
import GlobalStoreLoader from './GlobalStoreLoader';

datadogRum.init({
    applicationId: DATADOG_RUM_APP_ID!,
    clientToken: DATADOG_RUM_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: DATADOG_RUM_SERVICE,
    env: process.env.REACT_APP_ENVIRONMENT,
    version: '1.0.2',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
});
datadogRum.startSessionReplayRecording();

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Provider store={Store()}>
                <GlobalStoreLoader>
                    <Loader>
                        <App />
                    </Loader>
                </GlobalStoreLoader>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);
