import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { DangerouslyHighlightedTextStyled } from './DangerouslyHighlightedText.styled';

const DangerouslyHighlightedText = ({ text, textCenter, className, ...props }) => {
    return (
        <Box
            component={DangerouslyHighlightedTextStyled}
            position="relative"
            display="flex"
            justifyContent={textCenter ? 'center' : null}
            {...props}
        >
            <Box
                className={`${className} WP-highlighted-stroke`}
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />

            <Box
                className={`${className} WP-highlighted-color`}
                position="absolute"
                top={0}
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
                aria-hidden={true}
            />
        </Box>
    );
};

DangerouslyHighlightedText.propTypes = {
    text: PropTypes.string,
    textCenter: PropTypes.bool,
    className: PropTypes.string,
};

export default DangerouslyHighlightedText;
