import styled from 'styled-components';
import bgImage from '../../../assets/images/DailyRewards/background.png';

export const DailyRewardBannerStyled = styled.div`
    background-color: #fff;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 1) 100%
        ),
        url(${bgImage});

    background-position: center, left 90%;
    background-size: 100%, cover;
    background-repeat: no-repeat;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        background-size: 100%, 70%;
        background-position: center, left 65%;
    }

    //Unclaimed and Claimed
    .WP-calendar-banner-container {
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 16px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            flex-direction: row;
            gap: 20px;
        }
    }

    .WP-daily-reward-banner-text {
        text-align: left;
    }

    .WP-calendar-banner-button {
        white-space: nowrap;
        font-size: 14px;
        ${({ theme }) => theme.breakpoints.down('xs')} {
            padding-right: 45px;
            padding-left: 45px;
        }
    }

    //Unclaimed

    .WP-unclaimed-gifts {
        display: flex;
        flex-direction: row;
        gap: 18px;

        ${({ theme }) => theme.breakpoints.up('sm')} {
            gap: 4px;
        }
    }

    .WP-unclaimed-gift-quantities {
        position: absolute;
        left: 50%;
        bottom: -6px;
        transform: translateX(-50%);
    }

    // DailyRewads Modal
    .WP-daily-reward-before,
    .WP-daily-reward-after {
        width: 14px;
        height: 10px;

        position: absolute;
        top: 0;
        z-index: 2;

        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: rgba(110, 7, 105, 0.2);

        &:after {
            content: '';
            display: block;

            width: 10px;
            height: 16px;

            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);

            border-radius: 12px;
            background-color: #999999;
        }
    }
`;
