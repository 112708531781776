import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../hooks/useTranslation';

import { Typography } from 'portal-modules/core-ui';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '../ui-components/Button/Button';

import CountdownUTC from '../CountdownUTC/CountdownUTC';
import { DailyRewardsStyled } from './DailyRewards.styled';

import { dailyRewardsOp, dailyRewardsSel } from '../../../store/dailyReward';
import { shopSel } from '../../../store/shop';
import { DAILY_LOGIN_CALENDAR_MODAL } from '../../../constants/modals.constants';
import TooltipWrapper from '../Tooltip/Tooltip';
import { setGAEvent } from '../../../helpers/GA4Helper';

import titleImage from '../../../assets/images/DailyRewards/title.png';
import backgroundAvailableImage from '../../../assets/images/DailyRewards/background-available.png';
import WhiteHighlightedText from '../WhiteHighlightedText/WhiteHighlightedText';

const DailyRewards = () => {
    const theme = useTheme();
    const t = useTranslation();
    const dispatch = useDispatch();

    const breakpointsSM = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const breakpointsLG = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const breakpointsXL = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const commodities = useSelector(shopSel.commoditiesSelector);

    const calendarGifts = dailyRewards?.CalendarGifts;
    const megaReward = dailyRewards?.MegaReward;
    const dailyProgress = dailyRewards?.DailyProgress;
    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;

    useEffect(() => {
        setGAEvent('impression', {
            screen_name: 'daily_login_reward',
            screen_type: 'pop-up',
        });
    }, []);

    if (!dailyRewards) return false;

    const handleClaimReward = () => {
        !dailyGiftClaimed && dispatch(dailyRewardsOp.claimDailyRewards(false));

        setGAEvent('clickEvent', {
            screen_name: 'daily_login_reward',
            screen_type: 'pop-up',
            interaction_object: 'claim_CTA',
            interaction_object_category: 'gift',
            offer_id: dailyGiftClaimed ? `D${dailyProgress}` : `D${dailyProgress + 1}`,
        });
    };

    const handleCountDownCallback = () => {
        dispatch(dailyRewardsOp.getDailyRewards());

        dispatch(
            dailyRewardsOp.handleOpenModal({
                open: true,
                variant: DAILY_LOGIN_CALENDAR_MODAL,
            }),
        );
    };

    return (
        <Box
            component={DailyRewardsStyled}
            width="100%"
            bgcolor={theme.palette.common.white}
            data-testid="daily-reward-modal"
        >
            <div className="WP-daily-top-content">
                <Box className="WP-daily-header">
                    <img src={titleImage} alt="Daily rewards" />
                </Box>
                <Box className="WP-daily-body-container">
                    <Box mb={0} width="fit-content">
                        <CountdownUTC
                            text={t('daily.reward.timer')}
                            start={dailyRewards?.NextClaimDate}
                            interval={60 * 1000}
                            h
                            m
                            callback={() => handleCountDownCallback()}
                            isDailyReward
                        />
                    </Box>
                    <Box className="WP-daily-body">
                        {calendarGifts?.map((data, index) => (
                            <TooltipWrapper
                                header={t('daily.reward.tooltip.header')}
                                text={index === 0 ? '' : t('daily.reward.tooltip.body')}
                                key={`daily-box-${index}`}
                                className={`WP-daily-body-tooltip ${
                                    index === 6 ? 'WP-daily-body-day7' : ''
                                } ${[0, 3].includes(index) ? 'WP-daily-body-left-side' : ''}  ${
                                    [2, 5].includes(index) ? 'WP-daily-body-right-side' : ''
                                }`}
                            >
                                <Box
                                    className={`WP-daily-body-box 
                                    ${index === 6 && 'WP-daily-body-day7-box'}  
                                    ${dailyProgress > index && 'claimed'} 
                                    ${
                                        dailyProgress === index &&
                                        dailyGiftClaimed === false &&
                                        'to-claim'
                                    }`}
                                >
                                    <Box className="WP-daily-box-header">
                                        <span>
                                            {dailyProgress > index ? (
                                                <>{t('daily.reward.claimed')}</>
                                            ) : dailyGiftClaimed === false &&
                                              dailyProgress === index ? (
                                                <>{t('available')}</>
                                            ) : (
                                                <>
                                                    {t('day')} {index + 1}
                                                </>
                                            )}
                                        </span>
                                    </Box>
                                    <Box className="WP-daily-box-content">
                                        {data?.Gifts?.map((gift, i) => (
                                            <Box
                                                key={`gift-${i}`}
                                                className="WP-daily-box-content-item"
                                            >
                                                <Box position="relative">
                                                    {dailyProgress === index &&
                                                        dailyGiftClaimed === false && (
                                                            <Box
                                                                position="absolute"
                                                                component="img"
                                                                src={backgroundAvailableImage}
                                                                alt=""
                                                                width={[100, 150]}
                                                                className="WP-daily-box-content-background-available"
                                                            />
                                                        )}
                                                    <Box
                                                        component="img"
                                                        width={[40, 60]}
                                                        src={
                                                            commodities?.[gift.CommodityKey]?.image
                                                        }
                                                        alt="gift-img-daily"
                                                        className="WP-daily-box-content-item-image"
                                                    />
                                                </Box>
                                                <Box className="WP-daily-box-content-item-quantity ">
                                                    <WhiteHighlightedText
                                                        text={gift?.Quantity}
                                                        fontSize={['h6', 'h3']}
                                                        textCenter
                                                    />
                                                </Box>
                                            </Box>
                                        ))}
                                        {index === 6 && megaReward?.Gifts.length > 0 && (
                                            <>
                                                {megaReward?.Gifts?.map((mega, i) => (
                                                    <Box
                                                        key={`mega-gift-${i}`}
                                                        className="WP-daily-box-content-item"
                                                    >
                                                        <Box
                                                            component="img"
                                                            width={[40, 60]}
                                                            src={
                                                                commodities?.[mega.CommodityKey]
                                                                    ?.image
                                                            }
                                                            alt="gift-img-daily"
                                                        />
                                                        <Box className="WP-daily-box-content-item-quantity ">
                                                            <WhiteHighlightedText
                                                                text={mega?.Quantity}
                                                                fontSize={['h6', 'h3']}
                                                                textCenter
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </TooltipWrapper>
                        ))}
                    </Box>
                </Box>
            </div>
            <Box className="WP-daily-footer" px={7} pb={dailyGiftClaimed ? 3 : 0}>
                {dailyProgress !== calendarGifts.length && !dailyGiftClaimed && (
                    <Button
                        variant="secondary"
                        onClick={handleClaimReward}
                        data-testid="daily-reward-claim-btn"
                        size={
                            breakpointsXL
                                ? 'large'
                                : breakpointsLG
                                ? 'medium'
                                : breakpointsSM
                                ? 'small'
                                : 'extra-small'
                        }
                    >
                        {t('claim.rewards')}!
                    </Button>
                )}

                <Box
                    component={Typography}
                    variant="p14"
                    fWeight="medium"
                    color={theme.palette.brown.A600}
                    pt={3}
                    pb={dailyProgress === calendarGifts?.length ? 10 : 3}
                    textAlign="center"
                    data-testid="daily.reward.info"
                >
                    {t('daily.reward.info')}
                </Box>
            </Box>
        </Box>
    );
};

DailyRewards.propTypes = {
    theme: PropTypes.object,
};
export default DailyRewards;
