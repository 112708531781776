import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { MainStyled } from './Main.styled';
import { Box } from 'portal-modules/core-ui';
import { setGAEvent } from '../../../helpers/GA4Helper';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../store/global';
import { getStorageItem, removeStorageItem } from '../../../helpers/localStorage';
import { useMediaQuery } from '@material-ui/core';

export default function Main({ children }) {
    const history = useHistory();
    const location = useLocation();

    const authenticated = useSelector(globalSel.isAuthSelector);

    const theme = useSelector(globalSel.themeSelector);
    const pathname = window.location.pathname;
    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

    useEffect(() => {
        window.history.replaceState({}, document.title);
    }, [history]);

    useLayoutEffect(() => {
        if (location?.state) {
            setTimeout(() => {
                let section = document.getElementsByClassName(location.state)[0];

                if (section) {
                    const getSectionPosition = breakpointsMD
                        ? section.offsetTop - 60
                        : section.offsetTop;

                    getSectionPosition &&
                        window.scrollTo({
                            top: getSectionPosition,
                            behavior: 'smooth',
                        });
                }
            });
        } else if (window.scrollY && window.scrollY !== 0) {
            window.scrollTo({
                top: 0,
            });
        }
    }, [history, breakpointsXS, location]);

    useEffect(() => {
        const pagePathname = `${pathname === '/' ? '/home' : pathname}`;

        setGAEvent('clickEvent', {
            screen_name: pagePathname,
            screen_type: 'screen',
            screen_section: 'left_menu',
            interaction_object: pagePathname,
            interaction_object_category: 'redirect',
        });

        setGAEvent('impression', { screen_name: pagePathname, screen_type: 'screen' });
    }, [pathname]);

    useEffect(() => {
        const claimed = getStorageItem('claimed_clicked');
        if (authenticated && claimed) {
            removeStorageItem('claimed_clicked');
            history.push('/loyalty-point-shop');
        }
    }, [authenticated]);

    return (
        <Box
            component={MainStyled}
            position="relative"
            flexGrow={1}
            width={[1, 1, 'calc(100% - 520px)']}
            pt={[14, 14, 0]}
            pl={[0, 0, 60]}
            pr={[0, 0, 70]}
            minHeight="100vh"
        >
            <Box
                px={[5, 8]}
                pb={[5, 5, 5, 7]}
                height="100%"
                position="relative"
                zIndex={['initial', 1, 8]}
            >
                {children}
            </Box>
        </Box>
    );
}

Main.propTypes = {
    children: PropTypes.node,
};
