import styled from 'styled-components';

export const StepsTutorialModalStyled = styled.div`
    width: 360px;

    ${({ theme }) => theme.breakpoints.up('md')} {
        width: 1100px;
    }

    .WP-loyalty-points-learn-cards {
        display: flex;
        flex-direction: column;

        padding: 40px 5px;
        gap: 15px;

        background-color: ${({ theme }) => theme.palette.orange.A50};

        ${({ theme }) => theme.breakpoints.up('md')} {
            flex-direction: row;
            padding: 35px;
            gap: 18px;
        }

        .WP-loyalty-points-learn-card-title {
            text-align: center;

            font-family: ${({ theme }) => theme.typography.font.Inter};
            font-weight: ${({ theme }) => theme.typography.weight.bold};
            font-size: ${({ theme }) => theme.typography.size.h3.fontSize};
            color: ${({ theme }) => theme.palette.brown.main};
        }

        .WP-loyalty-points-learn-card {
            width: 100%;
            display: flex;
            flex-direction: column;

            overflow: auto;

            padding: 0px 25px;
            gap: 6px;

            .WP-loyalty-points-learn-card-image {
                width: 100%;
                border-radius: 6px;
            }

            .WP-loyalty-points-learn-card-text {
                font-family: ${({ theme }) => theme.typography.font.Inter};
                font-weight: ${({ theme }) => theme.typography.weight.medium};
                font-size: ${({ theme }) => theme.typography.size.p18.fontSize};
                color: ${({ theme }) => theme.palette.brown.main};

                ${({ theme }) => theme.breakpoints.up('md')} {
                    font-size: ${({ theme }) => theme.typography.size.h6.fontSize};
                }

                img {
                    isplay: inline;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 4px;
                }
            }
        }
    }
`;
