import React from 'react';

import { Box } from 'portal-modules/core-ui';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { globalSel } from '../../../store/global';

const AddToHomeScreenSkeleton = () => {
    const mainTheme = useSelector(globalSel.themeSelector);
    const theme = useTheme();

    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    return (
        <Box
            width={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius={8}
            bgcolor={mainTheme.palette.background.main}
            py={2}
            px={6}
        >
            <Box height={32} width={breakpointsXS ? 100 : 240}>
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>
            <Box height={breakpointsXS ? 38 : 48} width={breakpointsXS ? 160 : 240}>
                <Skeleton variant="rect" animation="wave" height="100%" width="100%" />
            </Box>
        </Box>
    );
};

export default AddToHomeScreenSkeleton;
