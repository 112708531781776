import React from 'react';
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const LinkStyled = styled(({ fullWidth, ...rest }) => <ReactRouterLink {...rest} />)`
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

    color: ${({ theme }) => theme.palette.text.main};
    font-family: ${({ theme }) => theme.typography.font.inter}, sans-serif;
    font-weight: 600;

    position: relative;
    transition: 200ms ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 2px solid;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &.large {
        font-size: 24px;
        line-height: 32px;
        padding: 10px 44px;
        border-radius: 10px;
    }

    &.medium {
        font-size: 20px;
        line-height: 28px;
        white-space: nowrap;
        padding: 8px 28px;
        border-radius: 10px;

        .custom-button-icon {
            width: 28px !important;
            height: 28px !important;
        }
    }

    &.small {
        font-size: 16px;
        line-height: 24px;
        padding: 5px 12px;
        border-radius: 8px;

        .custom-button-icon {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &.extra-small {
        font-size: 16px;
        line-height: 24px;
        padding: 2px 8px;
        border-radius: 6px;

        .custom-button-icon {
            width: 20px !important;
            height: 20px !important;
        }
    }

    &.facebook-btn {
        font-size: 14px;
        line-height: 24px;
        padding: 5px 8px;
        text-shadow: none;
        background-color: #245fdc;
        white-space: nowrap;
        border: none;

        &:hover {
            background-color: #2c55a9;
        }
    }

    &.scopely-btn {
        font-size: 14px;
        line-height: 24px;
        padding: 5px 8px;
        text-shadow: none;
        background-color: #4f5794;
        white-space: nowrap;
        border: none;

        &:hover {
            background-color: #3e4683;
        }
    }

    &.primary {
        background-color: ${({ theme }) => theme.palette.primary.main};
        border-color: ${({ theme }) => theme.palette.blue.A200};

        &:hover {
            background-color: ${({ theme }) => theme.palette.blue.A500};
        }

        &:active {
            background-color: ${({ theme }) => theme.palette.blue.A800};
        }

        &:disabled {
            background-color: ${({ theme }) => theme.palette.blue.A200};
            border-color: ${({ theme }) => theme.palette.blue.A200};
        }
    }

    &.secondary {
        background-color: ${({ theme }) => theme.palette.secondary.main};
        border-color: ${({ theme }) => theme.palette.secondary.main};

        &:hover {
            background-color: ${({ theme }) => theme.palette.green.A700};
            border-color: ${({ theme }) => theme.palette.green.A700};
        }

        &:active {
            background-color: ${({ theme }) => theme.palette.green.A800};
        }

        &:disabled {
            background-color: ${({ theme }) => theme.palette.green.A300};
            border-color: ${({ theme }) => theme.palette.green.A300};
        }
    }

    &.danger {
        background-color: ${({ theme }) => theme.palette.red.A800};
        border-color: ${({ theme }) => theme.palette.red.A800};

        &:hover {
            background-color: ${({ theme }) => theme.palette.red.A700};
            border-color: ${({ theme }) => theme.palette.red.A700};
        }

        &:active {
            background-color: ${({ theme }) => theme.palette.red.main};
            border-color: ${({ theme }) => theme.palette.red.main};
        }

        &:disabled {
            background-color: ${({ theme }) => theme.palette.red.A300};
            border-color: ${({ theme }) => theme.palette.red.A300};
        }
    }

    &.completed {
        background-color: ${({ theme }) => theme.palette.brown.A300};
        border-color: ${({ theme }) => theme.palette.brown.A300};
        pointer-events: none;
        touch-action: none;
        text-shadow: none;
    }

    &.outlined {
        background-color: transparent;
        border-color: ${({ theme }) => theme.palette.brown.main};
        color: ${({ theme }) => theme.palette.brown.main};

        &:hover {
            color: ${({ theme }) => theme.palette.brown.A700};
            border-color: ${({ theme }) => theme.palette.brown.A700};
        }

        &:active {
            color: ${({ theme }) => theme.palette.brown.A800};
            border-color: ${({ theme }) => theme.palette.brown.A800};
        }

        &:disabled {
            color: ${({ theme }) => theme.palette.brown.A400};
            border-color: ${({ theme }) => theme.palette.brown.A400};
        }
    }

    &.outlined-800 {
        background-color: transparent;
        border-color: ${({ theme }) => theme.palette.brown.main};
        color: ${({ theme }) => theme.palette.brown.main};

        &:hover {
            color: ${({ theme }) => theme.palette.brown.A800};
            border-color: ${({ theme }) => theme.palette.brown.A800};
        }

        &:active {
            color: ${({ theme }) => theme.palette.brown.A700};
            border-color: ${({ theme }) => theme.palette.brown.A700};
        }

        &:disabled {
            color: ${({ theme }) => theme.palette.brown.A400};
            border-color: ${({ theme }) => theme.palette.brown.A400};
        }
    }

    &.brown {
        background-color: ${({ theme }) => theme.palette.brown.A800};
        border-color: ${({ theme }) => theme.palette.brown.A800};
        color: ${({ theme }) => theme.palette.common.white};

        // &:hover {
        //     color: ${({ theme }) => theme.palette.brown.A700};
        //     border-color: ${({ theme }) => theme.palette.brown.A700};
        // }
        //
        // &:active {
        //     color: ${({ theme }) => theme.palette.brown.A800};
        //     border-color: ${({ theme }) => theme.palette.brown.A800};
        // }
        //
        // &:disabled {
        //     color: ${({ theme }) => theme.palette.brown.A400};
        //     border-color: ${({ theme }) => theme.palette.brown.A400};
        // }
    }

    &.loading {
        .button-icon {
            animation: spinner 1s infinite linear;
        }
    }

    &:disabled {
        cursor: default;
    }

    .button-effect {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
    }

    .button-text {
        position: relative;
        z-index: 1;

        display: flex;
        align-items: center;
    }

    .discount-text {
        text-decoration: line-through;
    }

    .button-icon {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
        display: inline-block;
    }

    @keyframes spinner {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &.shine::after {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 40px;
        height: 100%;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.1) 100%
        );
        transform: skewX(-35deg);
        z-index: 1;
        animation: shineSlide 5s infinite;
    }

    @keyframes shineSlide {
        0% {
            left: -50%; /* Empieza fuera de la vista a la izquierda */
        }
        30% {
            left: 120%; /* Mueve completamente a la derecha */
        }
        100% {
            left: 120%; /* Mantiene fuera de la vista a la derecha */
        }
    }
`;
