import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { FF_HOME_SCREEN_STORE } from '../../../constants/featureFlag.constants';
import { useFeatureToggle } from '../../../helpers/featureToggle';

function WebAppManifest() {
    const location = useLocation();
    const isFFHomeScreenStoreEnabled = useFeatureToggle(FF_HOME_SCREEN_STORE);
    const pathname = isFFHomeScreenStoreEnabled ? '/store' : '/';

    return (
        <Redirect
            to={{
                pathname,
                state: { from: location },
            }}
        />
    );
}

export default WebAppManifest;
