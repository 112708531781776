import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../../../hooks/useTranslation';

import { Box, Typography } from 'portal-modules/core-ui';
import { Button } from '../../../ui-components/Button/Button';
import { useMediaQuery } from '@material-ui/core';

import { setGAEvent } from '../../../../../helpers/GA4Helper';

import PaymentModalSkeleton from './PaymentModalSkeleton';
import { AccountModalStyled } from '../AccountModal.styled';
import { globalOp } from '../../../../../store/global';
import { dailyDealsOp } from '../../../../../store/dailyDeals';
import { deviceType } from '../../../../../helpers/deviceType';
import { withPurchase } from '../../../../../hoc/withPurchase';
import { shopOp, shopSel } from '../../../../../store/shop';
import { dailyRewardsOp, dailyRewardsSel } from '../../../../../store/dailyReward';

import {
    PAYMENT_SUCCESS_MODAL,
    DAILY_GIFT_MODAL,
    REFRESH_FAILURE_MODAL,
} from '../../../../../constants/modals.constants';
import { useState } from 'react';
import { Skeleton } from '@material-ui/lab';

const PaymentModal = ({
    theme,
    type,
    handlePurchase,
    message = '',
    data = {},
    callback = () => {},
    isDailyRewardCompleted = false,
}) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const checkMobileOrTablet = deviceType();
    const isLoading = useSelector(shopSel.isLoading);

    const breakpointsXS = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
    const breakpointsMD = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
    const processingOrder = useSelector(shopSel.offerDataSelector);
    const html = document.getElementsByTagName('html')[0].classList;

    const dailyRewards = useSelector(dailyRewardsSel.dailyRewardsSelector);
    const dailyProgress = dailyRewards?.DailyProgress;
    const dailyGiftClaimed = dailyRewards?.DailyGiftClaimed;

    const [openaAppLoading, setOpenAppLoading] = useState(false);

    useEffect(() => {
        html.add('disable-scroll');
    }, []);

    const handleCloseButton = () => {
        if (type === DAILY_GIFT_MODAL) {
            dispatch(dailyRewardsOp.handleCloseModal());

            setGAEvent('clickEvent', {
                screen_name: 'claim_daily_login_reward',
                screen_type: 'pop-up',
                interaction_object: 'continue',
                interaction_object_category: 'display',
                offer_id: dailyGiftClaimed ? `D${dailyProgress}` : `D${dailyProgress + 1}`,
            });

            return callback();
        }

        dispatch(globalOp.handleCloseModal({}));
        dispatch(shopOp.handleOfferData());
        html.remove('disable-scroll');
    };

    const handlePurchaseRetry = () => {
        dispatch(globalOp.handleCloseModal({}));
        if (type === REFRESH_FAILURE_MODAL) {
            dispatch(dailyDealsOp.getRefreshedDeals());
        } else {
            handlePurchase({
                commodity: processingOrder?.commodity,
                iapSku: processingOrder?.customParameters?.IapSku,
                storeListingName: processingOrder?.customParameters?.storeListingName,
                item: {
                    name: processingOrder?.item?.name,
                    price: processingOrder?.item?.price,
                    section: processingOrder?.item?.section,
                },
            })();
        }
    };

    const handleOpenApp = () => {
        setOpenAppLoading(true);

        window.location.href = 'yux://game/open';

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setOpenAppLoading(false);
                handleCloseButton();
                window.removeEventListener('visibilitychange', handleVisibilityChange);
                window.removeEventListener('blur', handleBlur);
            }
        };

        const handleBlur = () => {
            setOpenAppLoading(false);
            handleCloseButton();
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleBlur);
        };

        window.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleBlur);
    };

    return isLoading ? (
        <PaymentModalSkeleton />
    ) : (
        <Box
            component={AccountModalStyled}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            className={`${
                type === DAILY_GIFT_MODAL ? 'WP-daily-reward' : 'WP-account-modal'
            } WP-modal-small`}
        >
            <Box height={[160, 250]} position="relative" className="flex-center WP-modal-header">
                <Box className="WP-image-container">
                    <Box className="WP-image-bg flex-center" width={[82, 108]} height={[82, 108]}>
                        <Box
                            component="img"
                            src={
                                require(`../../../../../assets/images/Modal/${data.image}.png`)
                                    .default
                            }
                            alt="Payment Status"
                        />
                    </Box>
                </Box>
            </Box>

            <Box
                minHeight={
                    checkMobileOrTablet && type === PAYMENT_SUCCESS_MODAL
                        ? 250
                        : type === PAYMENT_SUCCESS_MODAL
                        ? 'auto'
                        : 250
                }
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                className="WP-modal-body"
                px={[4, 6]}
                pb={5}
                pt={2}
            >
                <Box>
                    <Box
                        component={Typography}
                        variant={['h6', 'h5', 'h4']}
                        fWeight="bold"
                        color={theme.palette.brown.main}
                        mb={3}
                    >
                        {t(`${data.title}`)}
                    </Box>

                    <Box
                        component={Typography}
                        variant={['p14', 'p16']}
                        fWeight="semiBold"
                        color={theme.palette.brown.A700}
                        mb={5}
                    >
                        {t(`${data.description}${message}`)}
                    </Box>

                    {isDailyRewardCompleted && (
                        <Box mt={10} mb={13}>
                            <Box
                                component={Typography}
                                className="WP-modal-title"
                                variant={['h6', 'h5', 'h4']}
                                fWeight="bold"
                                color={theme.palette.brown.main}
                                mb={3}
                            >
                                {t('daily.reward.completed.title')}
                            </Box>

                            <Box
                                component={Typography}
                                className="WP-modal-subtitle"
                                variant={['p14', 'p16']}
                                fWeight="semiBold"
                                color={theme.palette.brown.A700}
                                mb={5}
                            >
                                {t('daily.reward.completed.description')}
                            </Box>
                        </Box>
                    )}
                </Box>

                {type === PAYMENT_SUCCESS_MODAL || type === DAILY_GIFT_MODAL ? (
                    <Box display="flex" flexDirection="column">
                        {checkMobileOrTablet ? (
                            <>
                                {openaAppLoading ? (
                                    <Box height={64} width={300} p={2}>
                                        <Skeleton
                                            variant="text"
                                            animation="wave"
                                            height="100%"
                                            width="100%"
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            component={Button}
                                            variant="secondary"
                                            mb={2}
                                            size={
                                                breakpointsXS
                                                    ? 'small'
                                                    : breakpointsMD
                                                    ? 'medium'
                                                    : 'large'
                                            }
                                            onClick={handleOpenApp}
                                        >
                                            <div className="back-to-game-title">
                                                {t('back.to.game')}
                                            </div>
                                        </Box>

                                        <Box
                                            component={Button}
                                            variant="outlined"
                                            onClick={handleCloseButton}
                                            mb={2}
                                            size={
                                                breakpointsXS
                                                    ? 'small'
                                                    : breakpointsMD
                                                    ? 'medium'
                                                    : 'large'
                                            }
                                        >
                                            {t('continue')}
                                        </Box>
                                    </>
                                )}
                            </>
                        ) : (
                            <Box
                                component={Button}
                                variant="secondary"
                                onClick={handleCloseButton}
                                mb={2}
                                size={breakpointsXS ? 'small' : breakpointsMD ? 'medium' : 'large'}
                            >
                                {t('continue')}
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Box
                        component={Button}
                        variant="secondary"
                        onClick={handlePurchaseRetry}
                        mb={2}
                        size={breakpointsXS ? 'small' : breakpointsMD ? 'medium' : 'large'}
                    >
                        {t('retry')}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

PaymentModal.propTypes = {
    theme: PropTypes.object,
    type: PropTypes.string,
    message: PropTypes.string,
    data: PropTypes.object,
    handlePurchase: PropTypes.func,
    callback: PropTypes.func,
    isDailyRewardCompleted: PropTypes.bool,
};

export default withPurchase(PaymentModal);
