import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Link } from '../ui-components/Link/Link';

const LatestUpdatesCard = ({ item }) => {
    const theme = useTheme();

    return (
        <Box
            className="WP-latest-update-container"
            data-testid="latest-update-card"
            bgcolor={theme.palette.common.white}
            style={
                item.Config.Content.OverlayUrl && {
                    backgroundImage: `url(${item.Config.Content.OverlayUrl})`,
                }
            }
        >
            <Box className="WP-latest-update-content">
                {item.Config.Content.Blocks[0] && (
                    <Box className="WP-latest-update-header">
                        {item.Config.Content.Blocks[0].ImageUrl && (
                            <Box
                                style={
                                    item.Config.Content.Blocks[0].ImageUrl && {
                                        backgroundImage: `url(${item.Config.Content.Blocks[0].ImageUrl})`,
                                    }
                                }
                                className="WP-latest-update-header-title-image"
                                alt="WP-latest-update-header-title-image"
                            />
                        )}
                        {item.Config.Content.Blocks[0].Heading && (
                            <h6 className="WP-latest-update-header-title">
                                {item.Config.Content.Blocks[0].Heading}
                            </h6>
                        )}
                    </Box>
                )}

                {item.Config.Content.Blocks.length > 0 && (
                    <Box className="WP-latest-update-contents">
                        {item.Config.Content.Blocks.map(
                            (element, key) =>
                                key > 0 && (
                                    <Box
                                        className={`WP-latest-update-content-box ${
                                            element.ImageUrl && !element.Body
                                                ? 'WP-latest-update-content-box-image-full'
                                                : ''
                                        }
                                        ${
                                            element.ImageUrl && element.Body
                                                ? 'WP-latest-update-content-box-split'
                                                : ''
                                        }
                                        `}
                                        key={key}
                                    >
                                        {element.ImageUrl && (
                                            <Box
                                                className="WP-latest-update-content-box-element WP-latest-update-content-box-image"
                                                data-testid="latest-update-card-content-image"
                                                style={
                                                    element.ImageUrl && {
                                                        backgroundImage: `url(${element.ImageUrl})`,
                                                    }
                                                }
                                            />
                                        )}
                                        {element.Body && (
                                            <Box
                                                className={`WP-latest-update-content-box-element ${
                                                    !element.ImageUrl
                                                        ? 'WP-latest-update-content-box-element-full'
                                                        : ''
                                                }`}
                                            >
                                                <p>{element.Body}</p>
                                            </Box>
                                        )}
                                    </Box>
                                ),
                        )}
                    </Box>
                )}
            </Box>

            <Box className="WP-latest-update-footer">
                {item.Config.Content.Ctas.map((cta, key) => (
                    <Link
                        key={key}
                        to={cta.Link}
                        variant={cta.Type && cta.Type === 2 ? 'secondary' : 'primary'}
                        size="small"
                        fullWidth
                    >
                        {cta.Text || 'Take me there!'}
                    </Link>
                ))}
            </Box>
        </Box>
    );
};

LatestUpdatesCard.propTypes = {
    item: PropTypes.object,
};

export default LatestUpdatesCard;
