import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Typography } from 'portal-modules/core-ui';
import { WhiteHighlightedTextStyled } from './WhiteHighlightedText.styled';

const WhiteHighlightedText = ({ text, fontSize, textCenter, ...props }) => {
    return (
        <Box
            component={WhiteHighlightedTextStyled}
            position="relative"
            display="flex"
            justifyContent={textCenter ? 'center' : null}
            {...props}
        >
            <Box
                component={Typography}
                className="text-highlighted-black-new"
                variant={fontSize}
                fWeight="bolder"
            >
                {text}
            </Box>

            <Box
                component={Typography}
                className="WP-white-highlighted-gradient"
                variant={fontSize}
                fWeight="bolder"
                position="absolute"
                top={0}
            >
                {text}
            </Box>
        </Box>
    );
};

WhiteHighlightedText.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    textCenter: PropTypes.bool,
};

export default WhiteHighlightedText;
